<template>
  <v-container v-if="wordList.length == 0">
    <v-row class="justify-center align-center">
      <v-col class="justify-center align-center">
        <v-card class="pa-4">
          <span v-if="dataLoading">
            <v-progress-circular v-if="dataLoading" color="primary" :size="200" :width="4" indeterminate>
              Data Loading...</v-progress-circular
            >
          </span>
          <span v-else class="secondary--text">
            <p v-if="totalWordsLeft == 0">
              <b> Congratulations. You have made flashcards for all words with your current settings! </b>
              <br />
              <core-btn-navigation inverted app="flashcards" page="settings"
                ><v-icon>mdi-cog</v-icon></core-btn-navigation
              >
            </p>

            <p v-else>
              <b> Good job! </b>You have finished learning your new words for today. <br /><br />
              Go to "Recall" to practice or refresh to learn 10 new words. <br /><br />
              You have <b>{{ totalWordsLeft }}</b> words left to go! <br /><br />

              <core-btn-navigation app="flashcards" page="recall">Recall</core-btn-navigation>
            </p>
          </span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row class="d-flex justify-center">
      <v-col cols="1" lg="3">
        <core-btn-navigation inverted app="flashcards" page="settings"
          ><v-icon>mdi-cog</v-icon></core-btn-navigation
        >
      </v-col>
      <v-col cols="11" lg="6" class="d-flex justify-center">
        <h2 class="primary--text">{{ topWord.word }} {{ `(${topWord.pronounciation})` }}</h2>
      </v-col>
      <v-col cols="12" lg="3">
        <core-autocomplete
          v-model="searchedWord"
          :items="wordList"
          :search-input.sync="searchText"
          :filter="searchFilter"
          item-text="word"
          return-object
          prepend-icon="mdi-magnify"
          filled
          clearable
          placeholder="Press 'Enter' to search"
          @change="changeTopWord()"
          @keydown="search($event)">
        </core-autocomplete
      ></v-col>
    </v-row>
    <v-row>
      <v-col lg="4">
        <v-card class="pa-4">
          <v-row>
            <v-col cols="6"> Word </v-col>
            <v-col cols="6">
              {{ topWord.word }}
              <!-- Dictionary URL -->
              <v-tooltip color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openURL(dictionaryURL)">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span> Open in Dictionary</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"> Pronounciation </v-col>
            <v-col cols="6">
              {{ topWord.pronounciation }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"> Part of speech </v-col>
            <v-col cols="6">
              {{ topWord.part_of_speech }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"> Meanings </v-col>
            <v-col cols="6">
              {{ topWord.english_meanings }}
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col class="d-flex justify-end">
              <core-btn-secondary :loading="loading" @click="submit(true)">
                Mark as Already Mastered <v-icon class="ml-2">mdi-check</v-icon>
              </core-btn-secondary>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-6">
          <v-card-title
            ><v-icon class="mr-2">mdi-numeric-1-circle</v-icon>
            <h4 class="secondary--text">Pick an example sentence</h4></v-card-title
          >
          <v-row class="mx-1">
            <v-col cols="12" lg="8">
              <core-text-field dense label="Custom Sentence Search" v-model="customSentence" hide-details>
              </core-text-field>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <core-btn-primary inverted @click="loadExamples()">
                Search
                <v-icon small class="ml-2">mdi-magnify</v-icon>
              </core-btn-primary>
            </v-col>
          </v-row>
          <v-row v-if="sentencesLoading" class="d-flex justify-center">
            <v-col class="d-flex justify-center">
              <v-progress-circular size="40" :width="2" indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-else v-for="(sentence, index) in sentences" :key="index" class="ml-1">
            <v-col
              ><v-tooltip color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <span outlined text small color="primary" v-bind="attrs" v-on="on" v-html="sentence.text">
                  </span>
                </template>
                <span class="ma-1">
                  <v-row>{{ sentence.translation }}</v-row>
                  <v-row v-if="sentence.pronounciation">
                    <span class="text-body-1" v-html="sentence.pronounciation"></span>
                  </v-row>
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="1" class="py-0 d-flex align-center">
              <v-checkbox
                v-model="sentence.selected"
                hide-details
                class="mt-0"
                @click="selectExample(index)"></v-checkbox>
            </v-col>
            <v-col cols="2" class="py-0 d-flex align-center">
              <v-tooltip color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openURL(sentence.url)"
                    ><v-icon>mdi-open-in-new</v-icon></v-btn
                  >
                </template>
                <span> Open in Tatoeba</span>
              </v-tooltip>
            </v-col>
            <v-col v-if="sentence.audio" cols="12"> <audio controls :src="sentence.audio"></audio></v-col>
          </v-row>
        </v-card>
        <v-row v-if="user.is_superuser">
          <v-col>
            <iframe
              id="embed-preview-iframe"
              loading="eager"
              src="https://embed.pickaxeproject.com/axe?id=Japanese_AI_Word_Explainer_S1V7O&mode=embed_gold&host=beta&theme=light&opacity=100&font_header=Real+Head+Pro&size_header=30&font_body=Real+Head+Pro&size_body=16&font_labels=Real+Head+Pro&size_labels=14&font_button=Real+Head+Pro&size_button=16&c_fb=FFFFFF&c_ff=FFFFFF&c_fbd=888888&c_rb=FFFFFF&c_bb=228DD7&c_bt=FFFFFF&c_t=000000&s_ffo=100&s_rbo=100&s_bbo=100&s_f=minimalist&s_b=filled&s_t=1&s_to=1&s_r=2"
              width="100%"
              height="500px"
              class="transition hover:translate-y-[-2px] hover:shadow-[0_6px_20px_0px_rgba(0,0,0,0.15)]"
              style="
                border: 1px solid rgba(0, 0, 0, 1);
                transition: 0.3s;
                border-radius: 4px;
                max-width: 700px;
              "
              frameBorder="0"></iframe>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="5">
        <v-card class="pa-4">
          <v-row class="d-flex justify-center">
            <!-- <v-col cols="12" class="d-flex justify-center">
              <h3 class="primary--text">{{ topWord.word }} {{ `(${topWord.pronounciation})` }}</h3>
            </v-col>

            <v-col v-if="sentences.length > 0" cols="12" class="d-flex justify-center">
              <p>{{ sentences[selectedSentence].text }}</p>
            </v-col> -->

            <v-col cols="12" class="d-flex justify-center">
              <v-icon class="mr-2">mdi-numeric-2-circle</v-icon>
              <h3 class="secondary--text">Pick an image</h3>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" lg="5" class="d-flex align-center">
              <v-file-input
                v-model="imageUpload"
                placeholder="Upload your own image"
                accept="image/*"
                prepend-icon="mdi-camera"
                filled
                outlined
                dense></v-file-input>
            </v-col>
            <v-spacer />
            <v-col cols="12" lg="5">
              <core-text-field
                label="Custom Image Search"
                v-model="customSearch"
                dense
                hide-details
                @change="imageIndex = 0">
              </core-text-field>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center pt-0 mt-o">
              <core-btn-primary inverted :loading="loading" @click="refreshImages()">
                Refresh Images <v-icon>mdi-refresh</v-icon>
              </core-btn-primary>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center"> </v-row>
          <v-row class="d-flex justify-center" v-if="!imageUpload">
            <v-col v-if="imagesLoading" class="d-flex justify-center">
              <v-progress-circular size="40" :width="2" indeterminate color="primary"></v-progress-circular>
            </v-col>
            <v-col
              v-else
              v-for="(url, index) in urls"
              :key="index"
              lg="12"
              xl="6"
              class="d-flex justify-center">
              <a @click="selectImage(index)">
                <v-img
                  class="image"
                  :style="index == selectedImage ? 'border: 2px solid red' : ''"
                  :src="url"
                  :width="250"></v-img>
              </a>
            </v-col>
          </v-row>
        </v-card>
        <translator v-if="$vuetify.breakpoint.lgAndUp"> </translator>
      </v-col>
      <v-col lg="3">
        <v-row class="d-flex justify-start">
          <v-col cols="12" class="d-flex justify-start">
            <v-icon class="mr-2">mdi-numeric-3-circle</v-icon>
            <h3 class="secondary--text">(Optional) Write a sentence using this word</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-1">
            <v-textarea label="Personal Sentence" outlined v-model="hint"> </v-textarea>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-start">
          <v-col cols="12" class="d-flex justify-start">
            <v-icon class="mr-2">mdi-numeric-4-circle</v-icon>
            <h3 class="secondary--text">Add to deck</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <core-btn-primary :loading="loading" @click="submit(false)">
              Add to Flashcard Deck <v-icon class="ml-2">mdi-check</v-icon>
            </core-btn-primary>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.image:hover {
  transition: 0.4s ease;
  opacity: 0.7;
}
</style>

<script>
import * as functions from "@/javascript/functions"
import translator from "@/components/SRS/translator.vue"

export default {
  components: {
    "translator": translator,
  },

  data: function () {
    return {
      urls: ["", "", "", ""],
      onDeck: ["", "", "", ""],
      searchedWord: null,
      searchedWords: [],
      searchText: "",
      sentences: [],
      sentencesLoading: false,
      imagesLoading: false,
      imageUpload: null,
      selectedSentence: false,
      selectedImage: 0,
      imageIndex: 0,
      customSearch: "",
      customSentence: "",
      loading: false,
      hint: "",
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    dataLoading() {
      return this.$store.state.dataLoading
    },
    wordList() {
      return this.$store.state.srsNewWordList.concat(this.searchedWords)
    },
    totalWordsLeft() {
      let total = this.$store.state.srsNewWordTotal - (10 - this.wordList.length)
      if (total < 0) total = 0

      return total
    },
    topWord() {
      if (this.searchedWord) return this.searchedWord
      return this.wordList.length > 0 ? this.wordList[0] : ""
    },
    settings() {
      return this.$store.state.user.srs_user
    },
    dictionaryURL() {
      return `https://japandict.com/${this.topWord.word}`
    },
  },

  methods: {
    search(keyEvent) {
      if (keyEvent.key == "Enter") {
        this.$django_api
          .get(`srs/lexeme?search=${this.searchText}&new_words=True&language=${this.settings.language}`)
          .then((response) => {
            this.searchedWords = this.searchedWords.concat(response.data.lexemes)
          })
      }
    },
    searchFilter(item, queryText, itemText) {
      if (item.word.indexOf(queryText) > -1) {
        return true
      }

      if (item.english_meanings.indexOf(queryText) > -1) {
        return true
      }

      return false
    },
    async changeTopWord() {
      await this.loadPage(false)
    },
    openURL(url) {
      window.open(url, "_href")
    },
    loadOnDeckImages() {
      if (this.wordList.length < 2) return

      let onDeckWord = this.wordList[1]

      this.$django_api.get(`srs/image?word=${onDeckWord.word}&index=${0}`).then((response) => {
        this.onDeck = response.data
      })
    },
    loadExamples(audio = true) {
      if (this.wordList.length < 1) return

      let word = this.customSentence || this.topWord.word

      let languageMap = {
        "Japanese": "jpn",
        "Chinese": "cmn",
        "English": "eng",
      }
      let lang = languageMap[this.settings.language] ?? "eng"
      let has_audio = audio ? "yes" : "no"

      this.sentencesLoading = true
      this.$django_api
        .get(`srs/tatoeba?from=${lang}&query=${word}&has_audio=${has_audio}`)
        .then((response) => {
          if (response.data.length > 0) {
            this.constructExamples(response.data, audio)
          } else if (audio) {
            this.loadExamples(false)
          }
        })
        .catch((error) => {
          console.error(error)

          this.sentencesLoading = false
        })
    },
    constructExamples(results, audio = true) {
      this.sentences = []

      for (let result of results) {
        let text = result.text
        let pronounciation = ""
        if (result.lang == "cmn") {
          for (let transcription of result.transcriptions) {
            if (transcription.script == "Hans") text = transcription.text
            if (transcription.script == "Latn") pronounciation = transcription.html
          }
        } else if (result.lang == "jpn") {
          for (let transcription of result.transcriptions) {
            if (transcription.script == "Hrkt") pronounciation = transcription.html
          }
        }
        let example = {
          text: text,
          translation: result.translations[0][0].text,
          pronounciation: pronounciation,
          url: `https://tatoeba.org/en/sentences/show/${result.id}`,
          selected: false,
        }

        if (audio) {
          example.audio = `https://tatoeba.org/en/audio/download/${result.audios[0].id}`
        }

        this.sentences.push(example)
      }

      this.sentencesLoading = false
    },
    selectExample(index) {
      for (let i = 0; i < this.sentences.length; i++) {
        if (i == index) this.sentences[i].selected = true
        else this.sentences[i].selected = false
      }
      this.selectedSentence = index
    },

    async refreshImages() {
      this.loading = true

      let word = this.customSearch || this.topWord.word
      this.imagesLoading = true

      await this.$django_api.get(`srs/image?word=${word}&index=${this.imageIndex}`).then((response) => {
        this.urls = response.data
        this.imagesLoading = false
        this.$forceUpdate()
      })

      this.imageIndex += 4
      this.loading = false
    },
    selectImage(index) {
      this.selectedImage = index
    },
    async submit(mastered) {
      this.loading = true

      let payload = {
        lexeme_id: this.topWord.id,
        image_url: this.image_upload ? null : this.urls[this.selectedImage],
        hint: this.hint,
        level: 0,
        stage: "Recall",
      }

      if (this.sentences.length > 0 && this.selectedSentence !== false) {
        payload.sentence_text = this.sentences[this.selectedSentence].text
        payload.sentence_translation = this.sentences[this.selectedSentence].translation
        payload.sentence_pronounciation = this.sentences[this.selectedSentence].pronounciation
        payload.sentence_audio_src = this.sentences[this.selectedSentence].audio
        payload.sentence_url = this.sentences[this.selectedSentence].url
      }

      if (mastered) {
        payload.stage = "Mastered"
        payload.level = 2
      }

      let formData = new FormData()
      formData.append("data", JSON.stringify(payload))
      if (this.imageUpload != null) {
        formData.append("files", this.imageUpload)
      }

      await this.$django_api.post(`srs/userword/`, formData).then((response) => {
        if (!this.searchedWord) this.$store.state.srsNewWordList.shift()
        this.searchedWords = this.searchedWords.filter((lexeme) => lexeme.id != payload.lexeme_id)
        if (!mastered) this.$store.state.srsRecallList.push(response.data)
      })

      this.urls = this.onDeck
      this.loadOnDeckImages()

      this.imageUpload = null
      this.imageIndex = 4
      this.selectedImage = 0
      this.selectedSentence = false
      this.customSearch = ""
      this.customSentence = ""
      this.hint = ""
      this.searchedWord = null

      this.loadExamples()
      this.$forceUpdate()

      this.loading = false
    },

    async loadPage() {
      if (this.wordList.length == 0 && this.$store.dataLoading) {
        setTimeout(this.loadPage, 1000)
      } else if (this.wordList.length > 0) {
        this.loadExamples()
        await this.refreshImages()
        this.loadOnDeckImages()
      }
    },
  },
  mounted: async function () {
    await this.loadPage()
  },
}
</script>
